import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  InputBase,
  IconButton,
  Paper,
  Checkbox,
  ListItemAvatar,
  Avatar,
} from "@mui/material";
import { searchPost } from "../../AppCore/services/DashboardPostServices";
import SearchIcon from "@mui/icons-material/Search";
import { imagesBucketPath } from "../../AppCore/services/RecommendedBrokers";
import formatNumberToIndianSystem from "./constant";

interface PostListProps {
  categoryId: number;
  categoryEndpoint: string;
  cityName: string;
  userId: number;
  pageSize?: number;
  onPostSelect: (post: PostData | null) => void;
  brandFilter: any;
}

interface PostData {
  postId: number;
  heading: string;
  description: string;
  cityName: string;
  stateName: string;
  countryName: string;
  price: number;
  isBrokerAppVerified: boolean;
  title: string;
  placeName: string;
  placeID: string;
  postMedias: any[];
}

const PostList: React.FC<PostListProps> = ({
  categoryId,
  categoryEndpoint = "",
  cityName,
  userId,
  pageSize = 5,
  onPostSelect,
  brandFilter,
}) => {
  const [postData, setPostData] = useState<PostData[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const [postSearchKeyword, setPostSearchKeyword] = React.useState<string>("");
  const [pagination, setPagination] = useState({
    totalPage: 0,
    totalRecord: 0,
    currentPage: 1,
  });
  const [selectedPostId, setSelectedPostId] = useState<number | null>(null);

  const fetchPosts = async (pageNumber: number = 1, keyword: string = "") => {
    setLoading(true);
    setError("");
    var filters: any = {
      tags: [
        {
          name: "",
          values: [
            {
              key: "",
              value: "",
            },
          ],
        },
      ],
    };

    if (brandFilter) {
      filters.tags[0] = {
        name: categoryId === 1 ? "Developer" : "Brand",
        values: [
          {
            key: brandFilter.selectedBrandKey.toString || "",
            value: brandFilter.selectedBrandValue || "",
          },
        ],
      };
    }

    const request = {
      userId,
      keyWord: keyword || cityName,
      pageNumber,
      pageSize,
      cityName: cityName,
      filters,
    };
    try {
      const response = await searchPost(`${categoryEndpoint}`, request);
      const data = await response.data;
      console.log(data);
      if (data?.posts?.length > 0) {
        const postDataList = data.posts.map((item: any) => ({
          postId: item.postId,
          heading: `${item.title || ""}`,
          description: `${item.placeName || ""}`,
          cityName: item.location?.cityName || "",
          stateName: item.location?.stateName || "",
          countryName: item.location?.countryName || "",
          price: item.price || 0,
          isBrokerAppVerified: item.isBrokerAppVerified || false,
          title: item.title || "",
          placeName: item.placeName || "",
          placeID: item.placeID || "",
          postMedias: item.postMedias || [],
        }));

        setPostData(postDataList);
        setPagination({
          totalPage: data.totalPages || 0,
          totalRecord: data.recordCount || 0,
          currentPage: pageNumber,
        });
      } else {
        setPostData([]);
        setPagination({ totalPage: 0, totalRecord: 0, currentPage: 1 });
      }
    } catch (err) {
      console.error(err);
      setError("Failed to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (_: any, value: number) => {
    fetchPosts(value, postSearchKeyword);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostSearchKeyword(e.target.value);
  };

  const handleSearchSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    fetchPosts(1, postSearchKeyword);
    setPagination({ totalPage: 0, totalRecord: 0, currentPage: 1 });
  };

  const handleSelectPost = (postId: number) => {
    if (selectedPostId === postId) {
      setSelectedPostId(null);
      onPostSelect(null);
    } else {
      setSelectedPostId(postId);
      const selectedPost = postData.find((post) => post.postId === postId);
      if (selectedPost) {
        onPostSelect(selectedPost);
      }
    }
  };

  useEffect(() => {
    fetchPosts(1, postSearchKeyword);
  }, [categoryId, cityName]);
  const defaultImagePath =
    "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg";
  return (
    <div>
      <h3 style={{ marginBottom: 20 }}>Posts List</h3>
      {isLoading && <CircularProgress />}

      <Paper
        component="form"
        sx={{
          p: "2px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          marginBottom: 4,
        }}
        onSubmit={handleSearchSubmit}
      >
        <InputBase
          sx={{ ml: 1, flex: 1 }}
          name="searchKeywords"
          placeholder="Search By Keyword"
          inputProps={{ "aria-label": "Search By Keyword" }}
          value={postSearchKeyword}
          onChange={handleSearchChange}
        />
        {!isLoading ? (
          <Button type="submit" sx={{ p: "10px" }} aria-label="search">
            <SearchIcon />
          </Button>
        ) : (
          <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
            <CircularProgress size="30px" />
          </IconButton>
        )}
      </Paper>

      <List
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 5,
          width: "100%",
        }}
      >
        {postData.map((post) => {
          const media = post.postMedias?.[0] || null;
          const mediaBlobId = media?.mediaBlobId || "";
          const mediaType = media?.mediaType || "";

          const validExtensions = ["png", "jpeg", "webp"];

          const fileExtension = mediaBlobId.split(".").pop()?.toLowerCase();

          const isValidImage =
            fileExtension && validExtensions.includes(fileExtension);

          const mediaSrc = isValidImage
            ? `${imagesBucketPath}${mediaBlobId}`
            : defaultImagePath;

          const isVideo =
            mediaType.toLowerCase().includes("video") && isValidImage;
          return (
            <ListItem
              key={post.postId}
              sx={{
                border: "1px solid #ddd",
                borderRadius: 2,
                // padding: 2,
                // marginTop: 5,
              }}
            >
              <ListItemAvatar>
                {isVideo ? (
                  <img
                    alt="default media preview"
                    src={mediaSrc}
                    className="object-cover rounded"
                    style={{
                      height: 100,
                      width: 100,
                      marginRight: 20,
                      borderRadius: 10,
                    }}
                  />
                ) : (
                  <img
                    alt="media preview"
                    src={mediaSrc}
                    className="object-cover rounded"
                    style={{
                      height: 100,
                      width: 100,
                      marginRight: 20,
                      borderRadius: 10,
                    }}
                  />
                )}
              </ListItemAvatar>

              <ListItemText
                primary={<strong>{post.heading}</strong>}
                secondary={
                  <>
                    <p style={{ fontWeight: "bold", marginBottom: 5 }}>
                      Price: ₹{formatNumberToIndianSystem(post.price)}
                    </p>
                    <p>
                      Location: {post.cityName}, {post.stateName},{" "}
                      {post.countryName}
                    </p>
                    {/* {post?.developerName !== "" && (
                      <p>Brand: {post?.developerName}</p>
                    )} */}
                  </>
                }
              />
              <Checkbox
                checked={selectedPostId === post.postId}
                onChange={() => handleSelectPost(post.postId)}
                inputProps={{ "aria-label": "Select post" }}
              />
            </ListItem>
          );
        })}
      </List>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {!isLoading && postData.length === 0 && <p>No posts found.</p>}

      {pagination.totalPage > 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: 20,
          }}
        >
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}
    </div>
  );
};

export default PostList;
