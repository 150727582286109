import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CATEGORY, MarqueeFormat, MarqueueAction } from "./Dashboard/constant";
import GoogleMaps from "../Components/GoogleMaps";
import { IOption } from "../AppCore/types/DashboardPost";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../context/AuthContext";
import PostList from "./Dashboard/PostLists";
import AddAdsForm from "../Components/AddAdsForm";
import UploadAds from "../Components/UploadAdsBanner";
import { uploadToS3 } from "../AppCore/services/awsServices";
import { AddAdsApi } from "../AppCore/services/DashboardPostServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

// const steps = [
//   "Select Marquee Type",
//   "Select Action Type",
//   "Select Post",
//   "Submit the form",
// ];

export default function AddAds() {
  const { user } = useAuth();
  // console.log(user);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [category, setCategory] = React.useState<IOption | null>(null);
  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const navigate = useNavigate();
  const [mediaFile, setMediaFile] = useState(null);
  const [mediaType, setMediaType] = useState("");
  const [mediaPath, setMediaPath] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);

  const [errors, setErrors] = useState<{
    marqueeType?: string;
    actionType?: string;
    categoryId?: string;
  }>({});
  const [formData, setFormData] = useState({
    postId: 0,
    marqueueText: "",
    displayOrder: 0,
    categoryId: 0,
    fromDate: "",
    toDate: "",
    cityName: "",
    stateName: "",
    countryName: "",
    placeID: "",
    placeName: "",
    geoLocationLatitude: 28.6358781,
    geoLocationLongitude: 77.5065566,
    viewportNorthEastLat: 28.6358781,
    viewportNorthEastLng: 77.5065566,
    viewportSouthWestLat: 28.4085255,
    viewportSouthWestLng: 77.29709430000001,
    isGlobal: true,
    userId: user.userId,
    email: "",
    contactNo: "",
    marqueeType: 1,
    actionType: 1,
    adType: 1,
    targetUrl: "",
    postMedia: [
      {
        mediaBlobId: "",
        mediaType: "",
        mediaHeight: 0,
        mediaWidth: 0,
      },
    ],
  });
  const steps = [
    "Select Marquee Type",
    "Select Action Type",
    ...(formData.actionType === 1 ? ["Select Post"] : []),
    "Submit the form",
  ];
  const validateStep = () => {
    const newErrors: any = {};
    if (activeStep === 0 && formData.marqueeType === 0) {
      newErrors.marqueeType = "Please select a Marquee Type.";
    }
    if (activeStep === 1) {
      if (formData.actionType === 0) {
        newErrors.actionType = "Please select an Action Type.";
      }
      if (formData.categoryId === 0) {
        newErrors.categoryId = "Please select a Category.";
      }
      if (activeStep === 1 && formData.isGlobal === undefined) {
        newErrors.isGlobal = "Please select if the ad is global.";
      }
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (!validateStep()) return;
    if (formData.actionType !== 1 && activeStep === 1) {
      setActiveStep((prevStep) => prevStep + 2);
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };
  const handleMediaSelect = ({ file, url, type, path }: any) => {
    setMedia(url);
    setMediaFile(file);
    setMediaType(type);
    setMediaPath(path);
  };

  const handleThumbnailGenerate = ({ file, url }: any) => {
    setThumbnail(url);
    setThumbnailFile(file);
  };

  const handleThumbnailSelect = ({ file, url }: any) => {
    setThumbnail(url);
    setThumbnailFile(file);
  };

  const handleIsGlobalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFormData((prev) => ({ ...prev, isGlobal: isChecked }));
  };
  const handleBack = () => {
    if (formData.actionType !== 1 && activeStep === 3) {
      setActiveStep((prevStep) => prevStep - 2);
    } else {
      setActiveStep((prevStep) => prevStep - 1);
    }
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedCategory = CATEGORY.find((item) => item.id === selectedId);
    if (selectedCategory) {
      setCategory(selectedCategory);
      setFormData((prev) => ({ ...prev, categoryId: selectedId }));
    }
  };

  const handleMarqueeChange = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    setFormData((prev) => ({
      ...prev,
      marqueeType: selectedId,
    }));
  };

  const handleActionChange = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    setFormData((prev) => ({ ...prev, actionType: selectedId }));
  };
  const matchedCategory = CATEGORY.find(
    (cat) => cat.id === formData.categoryId
  );
  const handleLocationChange = (
    city: string,
    state: string,
    country: string,
    placeId: string,
    placeName: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      countryName: country,
      stateName: state,
      cityName: city,
      placeID: placeId,
      placeName,
    }));
  };
  const handleAdsFormSubmit = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      marqueueText: data.marqueue ?? "",
      toDate: data.toDate,
      email: data.email,
      fromDate: data.fromDate,
      contactNo: data.mobile,
      targetUrl: data.url ?? "",
      displayOrder: data.order,
    }));
    console.log(data);
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl
              sx={{ minWidth: 435, marginBottom: 1 }}
              error={!!errors.marqueeType}
            >
              <InputLabel id="marquee-label">Select Marquee Type</InputLabel>
              <Select
                labelId="marquee-label"
                id="marquee-type"
                value={formData.marqueeType ? String(formData.marqueeType) : ""}
                label="Marquee Type"
                onChange={handleMarqueeChange}
              >
                {MarqueeFormat.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color="error">{errors.marqueeType}</Typography>
            </FormControl>
          </div>
        );
      case 1:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl
              sx={{ minWidth: 435, marginBottom: 1 }}
              error={!!errors.actionType}
            >
              <InputLabel id="action-label">Select Action Type</InputLabel>
              <Select
                labelId="action-label"
                id="action-type"
                value={formData.actionType ? String(formData.actionType) : ""}
                label="Action Type"
                onChange={handleActionChange}
              >
                {MarqueueAction.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color="error">{errors.actionType}</Typography>
            </FormControl>
            <FormControl
              sx={{ minWidth: 435, marginBottom: 1 }}
              error={!!errors.categoryId}
            >
              <InputLabel id="category-label">Select Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={formData.categoryId ? String(formData.categoryId) : ""}
                label="Category"
                onChange={handleCategoryChange}
              >
                {CATEGORY.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    disabled={!item.isActive}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color="error">{errors.categoryId}</Typography>
            </FormControl>
            <GoogleMaps onLocationChange={handleLocationChange} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isGlobal}
                  onChange={handleIsGlobalChange}
                  name="isGlobal"
                  color="primary"
                />
              }
              label="Is Global"
            />
          </div>
        );
      case 2:
        return formData.actionType === 1 ? (
          <Grid container spacing={2} sx={{ height: "160vh", padding: 2 }}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  padding: 2,
                  height: "100%",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  borderRadius: 1,
                }}
              >
                <PostList
                  categoryId={formData.categoryId}
                  categoryEndpoint={matchedCategory?.endpoint || ""}
                  cityName={formData.cityName}
                  userId={user.userId}
                  onPostSelect={handlePostSelect}
                  brandFilter={null}
                />
              </Box>
            </Grid>
          </Grid>
        ) : (
          <Typography>Skipping this step.</Typography>
        );

      case 3:
        return (
          <Grid container spacing={2} sx={{ height: "120vh", padding: 2 }}>
            <Grid item xs={12} md={formData.marqueeType == 1 ? 12 : 6}>
              <Box
                sx={{
                  padding: 2,
                  height: "100%",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  borderRadius: 1,
                }}
              >
                <AddAdsForm
                  actionId={formData.actionType}
                  marqueeFormat={formData.marqueeType}
                  onSubmit={handleAdsFormSubmit}
                />
              </Box>
            </Grid>
            {formData.marqueeType !== 1 && (
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    padding: 2,
                    height: "100%",
                    overflowY: "auto",
                    border: "1px solid #ddd",
                    borderRadius: 1,
                  }}
                >
                  <UploadAds
                    marqueeFormat={formData.marqueeType} // Change this value based on your needs
                    onMediaSelect={handleMediaSelect}
                    onThumbnailGenerate={handleThumbnailGenerate}
                    onThumbnailSelect={handleThumbnailSelect}
                    loading={loading}
                    media={media}
                    thumbnail={thumbnail}
                  />
                </Box>
              </Grid>
            )}
          </Grid>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };
  const handlePostSelect = (post: any) => {
    if (post) {
      setFormData((prev) => ({ ...prev, postId: post.postId }));
    } else {
      console.log("Post deselected");
    }
  };
  const handleUpload = async () => {
    try {
      setLoading(true);

      if (mediaFile) {
        const timestamp = Date.now();
        const mediaFileName = uuidv4() + "_" + timestamp;

        const mediaS3Path = `podcasts/originals/${
          mediaType.includes("video")
            ? "videos"
            : mediaType.includes("gif")
            ? "gifs"
            : "images"
        }/${mediaFileName}${mediaPath.substring(mediaPath.lastIndexOf("."))}`;

        const mediaS3URL = await uploadToS3(
          "broker2023",
          mediaS3Path,
          mediaFile,
          mediaType
        );

        const updatedFormData = {
          ...formData,
          adType: formData.marqueeType === 1 ? 2 : 1,
          postMedia: [
            {
              mediaBlobId: mediaS3Path,
              mediaType: mediaType,
              mediaHeight: 0,
              mediaWidth: 0,
            },
          ],
        };
        console.log("marqueeType:", formData.marqueeType);
        console.log("adType being sent:", updatedFormData.adType);
        setFormData(updatedFormData);

        console.log(updatedFormData);
        const res = await AddAdsApi(updatedFormData);
        console.log(res);

        if (res.status === "success") {
          toast.success("Advertisement added successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/ads-list");
          }, 500);
        }
      } else {
        const updatedFormData = {
          ...formData,
          adType: formData.marqueeType === 1 ? 2 : 1,
        };
        console.log("FormData without media:", updatedFormData);
        const res = await AddAdsApi(updatedFormData);
        console.log(res);
        if (res.status === "success") {
          toast.success("Advertisement added successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/ads-list");
          }, 500);
        }
        console.log(res);
      }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Failed to upload advertisement. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  // const handleUpload = async () => {
  //   try {
  //     setLoading(true);
  //     if (mediaFile) {
  //       const timestamp = Date.now();
  //       const mediaFileName = uuidv4() + "_" + timestamp;
  //       // const thumbnailFileName = uuidv4() + "_" + timestamp;

  //       const mediaS3Path = `podcasts/originals/${
  //         mediaType.includes("video")
  //           ? "videos"
  //           : mediaType.includes("gif")
  //           ? "gifs"
  //           : "images"
  //       }/${mediaFileName}${mediaPath.substring(mediaPath.lastIndexOf("."))}`;

  //       // const thumbnailS3Path = `podcasts/originals/images/${thumbnailFileName}_thumbnail.jpeg`;

  //       const mediaS3URL = await uploadToS3(
  //         "broker2023",
  //         mediaS3Path,
  //         mediaFile,
  //         mediaType
  //       );
  //       setFormData((prev) => ({
  //         ...prev,
  //         postMedia: [
  //           ...prev.postMedia,
  //           {
  //             mediaBlobId: mediaS3URL,
  //             mediaType: "",
  //             mediaHeight: 0,
  //             mediaWidth: 0,
  //           },
  //         ],
  //       }));
  //     }
  //     console.log(JSON.stringify(formData));
  //     // const res = await AddAdsApi(formData);
  //     // console.log(res);
  //     // let thumbnailS3URL = null;
  //     // if (thumbnailFile) {
  //     //   thumbnailS3URL = await uploadToS3(
  //     //     "broker2023",
  //     //     thumbnailS3Path,
  //     //     thumbnailFile,
  //     //     "image/jpeg"
  //     //   );
  //     // }
  //   } catch (error) {
  //     console.error("Upload error:", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>
        {renderStepContent(activeStep)}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep < steps.length - 1 && (
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          )}
          {activeStep === 3 && (
            <Button variant="contained" color="primary" onClick={handleUpload}>
              Upload
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
