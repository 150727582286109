import React, { useState } from "react";
import { getIn, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  TextField,
  Stepper,
  Step,
  StepLabel,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Backdrop,
  CircularProgress,
  FormHelperText,
  Paper,
  Stack,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AdsCategoryPLan, CATEGORY, SpaceCategory } from "./Dashboard/constant";
import { useAuth } from "../context/AuthContext";
import { AddSubscriptionPLanApi } from "../AppCore/services/Subscription";
import { GridAddIcon, GridDeleteIcon } from "@mui/x-data-grid";
interface Ad {
  adCount: number;
  category: number;
  validity: number;
}

interface SpaceAd {
  spaceAdCount: number;
  category: number;
  validity: number;
}

interface Limits {
  posts: any;
  ads: Ad[];
  spaceAd: SpaceAd[];
}

interface AdditionalBenefits {
  posts: any;
  ads: Ad[];
  spaceAd: SpaceAd[];
}

interface FormValues {
  planName: string;
  planDescription: string;
  planType: any;
  limits: Limits;
  additionalBenefits: AdditionalBenefits;
  price: string;
  discountPercentage: number;
  currency: string;
  validityType: number;
  validityValue: number;
  activatedOn: string;
}
const validationSchema = Yup.object().shape({
  planName: Yup.string().required("Plan Name is required"),
  planDescription: Yup.string().required("Plan Description is required"),
  planType: Yup.number().required("Plan Type is required"),
  limits: Yup.object().shape({
    posts: Yup.number().required("Posts limit is required"),
    ads: Yup.array().of(
      Yup.object().shape({
        adCount: Yup.number().required("Ad Count is required"),
        category: Yup.number().required("Category is required"),
        validity: Yup.number().required("Validity is required"),
      })
    ),
    spaceAd: Yup.array().of(
      Yup.object().shape({
        spaceAdCount: Yup.number().required("Space Ad Count is required"),
        category: Yup.number().required("Category is required"),
        validity: Yup.number().required("Validity is required"),
      })
    ),
  }),
  additionalBenefits: Yup.object().shape({
    posts: Yup.number().required("Additional Posts is required"),
    ads: Yup.array().of(
      Yup.object().shape({
        adCount: Yup.number().required("Ad Count is required"),
        category: Yup.number().required("Category is required"),
        validity: Yup.number().required("Validity is required"),
      })
    ),
    spaceAd: Yup.array().of(
      Yup.object().shape({
        spaceAdCount: Yup.number().required("Space Ad Count is required"),
        category: Yup.number().required("Category is required"),
        validity: Yup.number().required("Validity is required"),
      })
    ),
  }),
  price: Yup.number().required("Price is required"),
  discountPercentage: Yup.number().required("Discount Percentage is required"),
  validityType: Yup.number().required("Validity Type is required"),
  validityValue: Yup.number().required("Validity Value is required"),
});
export default function AddPlans() {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuth();
  // console.log(user);
  const steps = [
    "Select your plan type",
    "Enter plan details",
    "Additional benefits",
    "Submit the form",
  ];

  const initialValues: FormValues = {
    planName: "",
    planDescription: "",
    planType: "",
    limits: {
      posts: "",
      ads: [],
      spaceAd: [],
    },
    additionalBenefits: {
      posts: "",
      ads: [],
      spaceAd: [],
    },
    price: "",
    discountPercentage: 0,
    currency: "",
    validityType: 1,
    validityValue: 0,
    activatedOn: new Date().toISOString(),
  };

  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setLoading(true);
        const processedValues = {
          ...values,
          planType: Number(values.planType),
          limits: {
            ...values.limits,
            posts: Number(values.limits.posts),
            ads: values.limits.ads.map((ad) => ({
              ...ad,
              adCount: Number(ad.adCount),
              category: Number(ad.category),
              validity: Number(ad.validity),
            })),
            spaceAd: values.limits.spaceAd.map((spaceAd) => ({
              ...spaceAd,
              spaceAdCount: Number(spaceAd.spaceAdCount),
              category: Number(spaceAd.category),
              validity: Number(spaceAd.validity),
            })),
          },
          additionalBenefits: {
            ...values.additionalBenefits,
            posts: Number(values.additionalBenefits.posts),
            ads: values.additionalBenefits.ads.map((ad) => ({
              ...ad,
              adCount: Number(ad.adCount),
              category: Number(ad.category),
              validity: Number(ad.validity),
            })),
            spaceAd: values.additionalBenefits.spaceAd.map((spaceAd) => ({
              ...spaceAd,
              spaceAdCount: Number(spaceAd.spaceAdCount),
              category: Number(spaceAd.category),
              validity: Number(spaceAd.validity),
            })),
          },
          price: Number(values.price),
          discountPercentage: Number(values.discountPercentage),
          validityType: Number(values.validityType),
          validityValue: Number(values.validityValue),
        };
        const response = await AddSubscriptionPLanApi(processedValues);
        if (response.status === "success") {
          toast.success("Plan added successfully!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/brands-list");
          }, 1000);
          // API call here
        }
      } catch (error) {
        console.error("Upload error:", error);
        toast.error("Failed to add plan. Please try again.");
      } finally {
        setLoading(false);
      }
    },
  });
  const validateStep = (stepNumber: number) => {
    let errors: Record<string, string> = {};

    switch (stepNumber) {
      case 0:
        if (!formik.values.planType) {
          errors.planType = "Plan type is required";
        }
        break;

      case 1:
        if (!formik.values.planName.trim()) {
          errors.planName = "Plan name is required";
        }
        if (!formik.values.planDescription.trim()) {
          errors.planDescription = "Plan description is required";
        }

        if (formik.values.planType === 1 && formik.values.limits.posts <= 0) {
          errors.posts = "Post count must be greater than 0";
        }

        if (formik.values.planType === 2) {
          const hasInvalidAds = formik.values.limits.ads.some(
            (ad) => ad.adCount <= 0 || ad.validity <= 0
          );
          if (hasInvalidAds) {
            errors.ads =
              "All ad counts and validity periods must be greater than 0";
          }
        }

        if (formik.values.planType === 3) {
          const hasInvalidSpaceAds = formik.values.limits.spaceAd.some(
            (ad) => ad.spaceAdCount <= 0 || ad.validity <= 0
          );
          if (hasInvalidSpaceAds) {
            errors.spaceAds =
              "All space ad counts and validity periods must be greater than 0";
          }
        }
        break;

      case 2:
        if (formik.values.planType === 2 || formik.values.planType === 3) {
          if (formik.values.additionalBenefits.posts < 0) {
            errors.additionalPosts = "Additional posts cannot be negative";
          }
        }

        if (formik.values.planType === 1 || formik.values.planType === 3) {
          const hasInvalidAds = formik.values.additionalBenefits.ads.some(
            (ad) => ad.adCount < 0 || ad.validity < 0
          );
          if (hasInvalidAds) {
            errors.additionalAds =
              "Ad counts and validity periods cannot be negative";
          }
        }

        if (formik.values.planType === 1 || formik.values.planType === 2) {
          const hasInvalidSpaceAds =
            formik.values.additionalBenefits.spaceAd.some(
              (ad) => ad.spaceAdCount < 0 || ad.validity < 0
            );
          if (hasInvalidSpaceAds) {
            errors.additionalSpaceAds =
              "Space ad counts and validity periods cannot be negative";
          }
        }
        break;

      case 3:
        if (
          formik.values.discountPercentage < 0 ||
          formik.values.discountPercentage > 100
        ) {
          errors.discountPercentage = "Discount must be between 0 and 100";
        }
        if (!formik.values.currency.trim()) {
          errors.currency = "Currency is required";
        }
        if (!formik.values.validityType) {
          errors.validityType = "Validity type is required";
        }
        if (formik.values.validityValue <= 0) {
          errors.validityValue = "Validity value must be greater than 0";
        }
        break;
    }

    return errors;
  };
  const handleNext = async () => {
    const errors = validateStep(activeStep);

    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        toast.error(`${field}: ${message}`);
      });
      return;
    }

    if (activeStep === steps.length - 1) {
      formik.submitForm();
    } else {
      setActiveStep((prevStep) => prevStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };
  const addNewAd = (type: any) => {
    const newAd = { adCount: "", category: "", validity: "" };
    if (type === "limits") {
      formik.setFieldValue("limits.ads", [...formik.values.limits.ads, newAd]);
    } else {
      formik.setFieldValue("additionalBenefits.ads", [
        ...formik.values.additionalBenefits.ads,
        newAd,
      ]);
    }
  };
  const getDisabledCategories = (
    selectedCategories: number[],
    currentIndex: number
  ) => {
    // Exclude the current row's selection from being considered for disabling
    return selectedCategories.filter((_, index) => index !== currentIndex);
  };
  const addNewSpaceAd = (type: any) => {
    const newSpaceAd = { spaceAdCount: "", category: "", validity: "" };
    if (type === "limits") {
      formik.setFieldValue("limits.spaceAd", [
        ...formik.values.limits.spaceAd,
        newSpaceAd,
      ]);
    } else {
      formik.setFieldValue("additionalBenefits.spaceAd", [
        ...formik.values.additionalBenefits.spaceAd,
        newSpaceAd,
      ]);
    }
  };
  const removeAd = (index: any, type: any) => {
    if (type === "limits") {
      const newAds = [...formik.values.limits.ads];
      newAds.splice(index, 1);
      formik.setFieldValue("limits.ads", newAds);
    } else {
      const newAds = [...formik.values.additionalBenefits.ads];
      newAds.splice(index, 1);
      formik.setFieldValue("additionalBenefits.ads", newAds);
    }
  };

  const removeSpaceAd = (index: any, type: any) => {
    if (type === "limits") {
      const newSpaceAds = [...formik.values.limits.spaceAd];
      newSpaceAds.splice(index, 1);
      formik.setFieldValue("limits.spaceAd", newSpaceAds);
    } else {
      const newSpaceAds = [...formik.values.additionalBenefits.spaceAd];
      newSpaceAds.splice(index, 1);
      formik.setFieldValue("additionalBenefits.spaceAd", newSpaceAds);
    }
  };
  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <FormControl fullWidth>
            <InputLabel id="plan-type-label">Plan Type</InputLabel>
            <Select
              labelId="plan-type-label"
              id="planType"
              name="planType"
              value={formik.values.planType}
              onChange={formik.handleChange}
              error={formik.touched.planType && Boolean(formik.errors.planType)}
            >
              <MenuItem value={1}>General</MenuItem>
              <MenuItem value={2}>Ads</MenuItem>
              <MenuItem value={3}>Space</MenuItem>
            </Select>
            {/* {formik.touched.planType && formik.errors.planType && (
              <Typography color="error" variant="caption" sx={{ mt: 1 }}>
                {formik.errors.planType}
              </Typography>
            )} */}
          </FormControl>
        );

      case 1:
        return (
          <Stack spacing={3}>
            <TextField
              fullWidth
              label="Plan Name"
              value={formik.values.planName}
              onChange={formik.handleChange}
              name="planName"
              onBlur={formik.handleBlur}
              error={formik.touched.planName && Boolean(formik.errors.planName)}
              helperText={formik.touched.planName && formik.errors.planName}
            />
            <TextField
              fullWidth
              label="Plan Description"
              value={formik.values.planDescription}
              onBlur={formik.handleBlur}
              error={
                formik.touched.planDescription &&
                Boolean(formik.errors.planDescription)
              }
              helperText={
                formik.touched.planDescription && formik.errors.planDescription
              }
              onChange={formik.handleChange}
              name="planDescription"
              multiline
              rows={3}
            />

            {formik.values.planType === 1 && (
              <TextField
                fullWidth
                // type="number"
                label="Post Count"
                onBlur={formik.handleBlur}
                value={formik.values.limits.posts}
                error={
                  formik.touched.limits?.posts &&
                  Boolean(formik.errors.limits?.posts)
                }
                onChange={(e) =>
                  formik.setFieldValue("limits.posts", parseInt(e.target.value))
                }
              />
            )}

            {formik.values.planType === 2 && (
              <Box>
                {formik.values.limits.ads.map((ad, index) => {
                  const selectedCategories = formik.values.limits.ads.map(
                    (adItem) => adItem.category
                  );

                  const disabledCategories = getDisabledCategories(
                    selectedCategories,
                    index
                  );
                  return (
                    <Paper key={index} sx={{ p: 2, mb: 2 }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          fullWidth
                          // type="number"
                          label="Ad Count"
                          value={ad.adCount}
                          error={
                            getIn(
                              formik.touched,
                              `limits.ads.${index}.adCount`
                            ) &&
                            Boolean(
                              getIn(
                                formik.errors,
                                `limits.ads.${index}.adCount`
                              )
                            )
                          }
                          helperText={
                            getIn(
                              formik.touched,
                              `limits.ads.${index}.adCount`
                            ) &&
                            getIn(formik.errors, `limits.ads.${index}.adCount`)
                          }
                          onChange={(e) =>
                            formik.setFieldValue(
                              `limits.ads.${index}.adCount`,
                              parseInt(e.target.value)
                            )
                          }
                        />

                        <FormControl fullWidth>
                          <InputLabel id={`ads-category-label-${index}`}>
                            Category
                          </InputLabel>
                          <Select
                            labelId={`ads-category-label-${index}`}
                            label="Category"
                            value={ad.category?.toString() || ""}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `limits.ads.${index}.category`,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            {AdsCategoryPLan.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.id.toString()}
                                disabled={disabledCategories.includes(
                                  option.id
                                )}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          // type="number"
                          label="Validity"
                          value={ad.validity}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `limits.ads.${index}.validity`,
                              parseInt(e.target.value)
                            )
                          }
                          error={
                            getIn(
                              formik.touched,
                              `limits.ads.${index}.validity`
                            ) &&
                            Boolean(
                              getIn(
                                formik.errors,
                                `limits.ads.${index}.validity`
                              )
                            )
                          }
                          helperText={
                            getIn(
                              formik.touched,
                              `limits.ads.${index}.validity`
                            ) &&
                            getIn(formik.errors, `limits.ads.${index}.validity`)
                          }
                        />
                        <IconButton
                          onClick={() => removeAd(index, "limits")}
                          color="error"
                        >
                          <GridDeleteIcon />
                        </IconButton>
                      </Stack>
                    </Paper>
                  );
                })}
                <Button
                  startIcon={<GridAddIcon />}
                  onClick={() => addNewAd("limits")}
                  variant="outlined"
                  fullWidth
                >
                  Add Ad Row
                </Button>
              </Box>
            )}

            {formik.values.planType === 3 && (
              <Box>
                {formik.values.limits.spaceAd.map((spaceAd, index) => {
                  const selectedCategories = formik.values.limits.spaceAd.map(
                    (adItem) => adItem.category
                  );

                  const disabledCategories = getDisabledCategories(
                    selectedCategories,
                    index
                  );
                  return (
                    <Paper key={index} sx={{ p: 2, mb: 2 }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          fullWidth
                          // type="number"
                          label="Space Ad Count"
                          value={spaceAd.spaceAdCount}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `limits.spaceAd.${index}.spaceAdCount`,
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <FormControl fullWidth>
                          <InputLabel id={`space-category-label-${index}`}>
                            Category
                          </InputLabel>
                          <Select
                            labelId={`space-category-label-${index}`}
                            label="Category"
                            value={spaceAd.category?.toString() || ""}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `limits.spaceAd.${index}.category`,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            {SpaceCategory.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.id.toString()}
                                disabled={disabledCategories.includes(
                                  option.id
                                )}
                              >
                                {option.category}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          // type="number"
                          label="Validity"
                          value={spaceAd.validity}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `limits.spaceAd.${index}.validity`,
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <IconButton
                          onClick={() => removeSpaceAd(index, "limits")}
                          color="error"
                        >
                          <GridDeleteIcon />
                        </IconButton>
                      </Stack>
                    </Paper>
                  );
                })}
                <Button
                  startIcon={<GridAddIcon />}
                  onClick={() => addNewSpaceAd("limits")}
                  variant="outlined"
                  fullWidth
                >
                  Add Space Ad Row
                </Button>
              </Box>
            )}
          </Stack>
        );
      case 2:
        return (
          <Stack spacing={3}>
            <Typography variant="h6">Additional Benefits</Typography>

            {/* Additional Posts */}
            {(formik.values.planType === 2 || formik.values.planType === 3) && (
              <TextField
                fullWidth
                // type="number"
                label="Additional Posts"
                value={formik.values.additionalBenefits.posts}
                onChange={(e) =>
                  formik.setFieldValue(
                    "additionalBenefits.posts",
                    parseInt(e.target.value)
                  )
                }
              />
            )}

            {/* Additional Ads */}
            {(formik.values.planType === 1 || formik.values.planType === 3) && (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Additional Ads
                </Typography>
                {formik.values.additionalBenefits.ads.map((ad, index) => {
                  const selectedCategories =
                    formik.values.additionalBenefits.ads.map(
                      (adItem) => adItem.category
                    );

                  const disabledCategories = getDisabledCategories(
                    selectedCategories,
                    index
                  );
                  return (
                    <Paper key={index} sx={{ p: 2, mb: 2 }}>
                      <Stack direction="row" spacing={2} alignItems="center">
                        <TextField
                          fullWidth
                          // type="number"
                          label="Ad Count"
                          value={ad.adCount}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `additionalBenefits.ads.${index}.adCount`,
                              parseInt(e.target.value)
                            )
                          }
                        />

                        <FormControl fullWidth>
                          <InputLabel
                            id={`additional-ads-category-label-${index}`}
                          >
                            Category
                          </InputLabel>
                          <Select
                            labelId={`additional-ads-category-label-${index}`}
                            label="Category"
                            value={ad.category?.toString() || ""}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `additionalBenefits.ads.${index}.category`,
                                parseInt(e.target.value)
                              )
                            }
                          >
                            {AdsCategoryPLan.map((option) => (
                              <MenuItem
                                key={option.id}
                                value={option.id.toString()}
                                disabled={disabledCategories.includes(
                                  option.id
                                )}
                              >
                                {option.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <TextField
                          fullWidth
                          // type="number"
                          label="Validity"
                          value={ad.validity}
                          onChange={(e) =>
                            formik.setFieldValue(
                              `additionalBenefits.ads.${index}.validity`,
                              parseInt(e.target.value)
                            )
                          }
                        />
                        <IconButton
                          onClick={() => removeAd(index, "additionalBenefits")}
                          color="error"
                        >
                          <GridDeleteIcon />
                        </IconButton>
                      </Stack>
                    </Paper>
                  );
                })}
                <Button
                  startIcon={<GridAddIcon />}
                  onClick={() => addNewAd("additionalBenefits")}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 3 }}
                >
                  Add Additional Ad
                </Button>
              </Box>
            )}

            {/* Additional Space Ads */}
            {(formik.values.planType === 1 || formik.values.planType === 2) && (
              <Box>
                <Typography variant="subtitle1" sx={{ mb: 2 }}>
                  Additional Space Ads
                </Typography>
                {formik.values.additionalBenefits.spaceAd.map(
                  (spaceAd, index) => {
                    const selectedCategories =
                      formik.values.additionalBenefits.spaceAd.map(
                        (adItem) => adItem.category
                      );

                    const disabledCategories = getDisabledCategories(
                      selectedCategories,
                      index
                    );
                    return (
                      <Paper key={index} sx={{ p: 2, mb: 2 }}>
                        <Stack direction="row" spacing={2} alignItems="center">
                          <TextField
                            fullWidth
                            // type="number"
                            label="Space Ad Count"
                            value={spaceAd.spaceAdCount}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `additionalBenefits.spaceAd.${index}.spaceAdCount`,
                                parseInt(e.target.value)
                              )
                            }
                          />

                          <FormControl fullWidth>
                            <InputLabel
                              id={`additional-space-category-label-${index}`}
                            >
                              Category
                            </InputLabel>
                            <Select
                              labelId={`additional-space-category-label-${index}`}
                              label="Category"
                              value={spaceAd.category?.toString() || ""}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `additionalBenefits.spaceAd.${index}.category`,
                                  parseInt(e.target.value)
                                )
                              }
                            >
                              {SpaceCategory.map((option) => (
                                <MenuItem
                                  key={option.id}
                                  value={option.id.toString()}
                                  disabled={disabledCategories.includes(
                                    option.id
                                  )}
                                >
                                  {option.category}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <TextField
                            fullWidth
                            // type="number"
                            label="Validity"
                            value={spaceAd.validity}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `additionalBenefits.spaceAd.${index}.validity`,
                                parseInt(e.target.value)
                              )
                            }
                          />
                          <IconButton
                            onClick={() =>
                              removeSpaceAd(index, "additionalBenefits")
                            }
                            color="error"
                          >
                            <GridDeleteIcon />
                          </IconButton>
                        </Stack>
                      </Paper>
                    );
                  }
                )}
                <Button
                  startIcon={<GridAddIcon />}
                  onClick={() => addNewSpaceAd("additionalBenefits")}
                  variant="outlined"
                  fullWidth
                  sx={{ mb: 3 }}
                >
                  Add Additional Space Ad
                </Button>
              </Box>
            )}
          </Stack>
        );

      case 3:
        return (
          <Box component="form" noValidate autoComplete="off">
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 2, mt: 2 }}
            >
              <TextField
                fullWidth
                id="price"
                name="price"
                label="Price"
                value={formik.values.price}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.price && Boolean(formik.errors.price)}
                helperText={formik.touched.price && formik.errors.price}
              />

              <TextField
                fullWidth
                id="discountPercentage"
                name="discountPercentage"
                label="Discount Percentage"
                type="number"
                value={formik.values.discountPercentage}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.discountPercentage &&
                  Boolean(formik.errors.discountPercentage)
                }
                helperText={
                  formik.touched.discountPercentage &&
                  formik.errors.discountPercentage
                }
              />

              <TextField
                fullWidth
                id="currency"
                name="currency"
                label="Currency"
                value={formik.values.currency}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.currency && Boolean(formik.errors.currency)
                }
                helperText={formik.touched.currency && formik.errors.currency}
              />
              <Select
                labelId="validityType-label"
                id="validityType"
                name="validityType"
                value={formik.values.validityType}
                onChange={formik.handleChange}
                error={
                  formik.touched.validityType &&
                  Boolean(formik.errors.validityType)
                }
              >
                <MenuItem value={1}>Days</MenuItem>
                <MenuItem value={2}>Hours</MenuItem>
              </Select>
              <TextField
                fullWidth
                id="validityValue"
                name="validityValue"
                label="Validity Value"
                // type="number"
                value={formik.values.validityValue}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.validityValue &&
                  Boolean(formik.errors.validityValue)
                }
                helperText={
                  formik.touched.validityValue && formik.errors.validityValue
                }
              />
            </Box>
          </Box>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={{ mt: 4 }}>
        {renderStepContent(activeStep)}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          <Button variant="contained" onClick={handleNext} disabled={loading}>
            {activeStep === steps.length - 1
              ? loading
                ? "Uploading..."
                : "Upload"
              : "Next"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
