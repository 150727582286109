import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  IconButton,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  AddBrandsListApi,
  deleteDashboardPost,
  listAdsApi,
} from "../AppCore/services/DashboardPostServices";
import { imagesBucketPath } from "../AppCore/services/RecommendedBrokers";
import { useAuth } from "../context/AuthContext";

const BrandsAssosiateList: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const { user } = useAuth();
  const [postSearchKeyword, setPostSearchKeyword] = useState<string>("");
  const [pagination, setPagination] = useState({
    totalPage: 0,
    totalRecord: 0,
    currentPage: 1,
  });
  const [postData, setPostData] = useState<any[]>([]);
  const [selectedPost, setSelectedPost] = useState<any | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const fetchPosts = async (pageNumber: number = 1, pageSize: number = 10) => {
    setLoading(true);
    setError("");

    const request = {
      pageNumber,
      pageSize,
      cityName: "",
      keyword: postSearchKeyword,
    };

    try {
      const response = await AddBrandsListApi(request);
      const data = response.data;
      setPostData(data.posts || []);
      setPagination({
        totalPage: Math.max(1, data.totalPage || 1),
        totalRecord: data.totalRecord || 0,
        currentPage: pageNumber,
      });
    } catch (err) {
      console.error(err);
      setError("Failed to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (_: any, value: number) => {
    fetchPosts(value, 10); // Fixed pageSize to 10
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostSearchKeyword(e.target.value);
  };

  const handleSearchSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    fetchPosts(1, 10);
  };

  const handleOpenDialog = (post: any) => {
    setSelectedPost(post);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedPost(null);
  };

  const handleConfirmDelete = async () => {
    if (selectedPost) {
      try {
        await deleteDashboardPost(
          "/Dashboard/BrandAssociate",
          user.userId,
          selectedPost.postId,
          selectedPost.categoryId,
          selectedPost.id
        );

        // Calculate the new total pages after deletion
        const newTotalRecord = pagination.totalRecord - 1;
        const newTotalPages = Math.max(1, Math.ceil(newTotalRecord / 10));

        // If we're on the last page and it's now empty, go to previous page
        const shouldGoToPreviousPage =
          pagination.currentPage > newTotalPages ||
          (pagination.currentPage === newTotalPages && postData.length === 1);

        const newCurrentPage = shouldGoToPreviousPage
          ? Math.max(1, pagination.currentPage - 1)
          : pagination.currentPage;

        // Refetch the current page with updated data
        await fetchPosts(newCurrentPage, 10);
      } catch (error) {
        console.error("Failed to delete post", error);
        setError("Failed to delete post. Please try again later.");
      } finally {
        handleCloseDialog();
      }
    }
  };

  useEffect(() => {
    fetchPosts(1, 10);
  }, []);

  const defaultImagePath =
    "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg";

  return (
    <div>
      <h3 style={{ marginBottom: 20 }}>Brands List</h3>
      {isLoading && <CircularProgress />}

      <List
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 5,
          width: "100%",
        }}
        style={{ width: "100%" }}
      >
        {postData.map((post) => {
          const media = post.postMedias?.[0] || null;
          const mediaBlobId = `${imagesBucketPath}${media?.mediaBlobId}` || "";
          const mediaType = media?.mediaType || "";
          const validExtensions = ["png", "jpeg", "jpg", "gif", "webp"];
          const fileExtension = mediaBlobId.split(".").pop()?.toLowerCase();
          const isValidImage =
            fileExtension && validExtensions.includes(fileExtension);
          const mediaSrc = isValidImage ? mediaBlobId : defaultImagePath;
          const isVideo = mediaType.toLowerCase().includes("video");

          return (
            <ListItem
              key={post.postId}
              sx={{
                border: "1px solid #ddd",
                borderRadius: 2,
              }}
            >
              <ListItemAvatar>
                {isVideo ? (
                  <video
                    width={100}
                    height={100}
                    src={mediaBlobId}
                    className="object-cover rounded"
                    style={{ marginRight: 20 }}
                    autoPlay={true}
                  />
                ) : (
                  <img
                    alt="media preview"
                    src={mediaSrc}
                    className="object-cover rounded"
                    style={{
                      height: 100,
                      width: 100,
                      marginRight: 20,
                      borderRadius: 10,
                    }}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={post.marqueueText}
                secondary={`Location: ${post.cityName}, ${post.stateName}, ${post.countryName}`}
                sx={{ flex: 1 }}
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleOpenDialog(post)}
                sx={{ color: "red" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>

      {error && <p style={{ color: "red" }}>{error}</p>}
      {!isLoading && postData.length === 0 && <p>No posts found.</p>}

      {pagination.totalPage > 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: 20,
          }}
        >
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={handlePageChange}
          />
        </div>
      )}

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this post?</p>
          <p>
            <strong>{selectedPost?.marqueueText}</strong>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default BrandsAssosiateList;
