import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { TextField } from "@mui/material";
import { DISPLAY_ORDER } from "../pages/Dashboard/constant";

export default function AddAdsForm({ actionId, marqueeFormat, onSubmit }: any) {
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset today's date to midnight for comparison

  const validationSchema = Yup.object().shape({
    order: Yup.number()
      .min(1, "Please select a valid display order.")
      .required("Display order is required."),
    email: Yup.string()
      .email("Invalid email format.")
      .required("Email is required."),
    mobile: Yup.string()
      .matches(/^\d{10}$/, "Mobile number must be 10 digits.")
      .required("Mobile number is required."),
    fromDate: Yup.date()
      .min(today, "From Date cannot be earlier than today.")
      .required("From Date is required."),
    toDate: Yup.date()
      .min(Yup.ref("fromDate"), "To Date cannot be earlier than From Date.")
      .test(
        "is-not-equal",
        "To Date should not be the same as From Date.",
        function (value) {
          const fromDate = this.resolve(Yup.ref("fromDate")) as
            | Date
            | undefined;
          return value && (!fromDate || value > fromDate);
        }
      )
      .required("To Date is required."),
    url:
      actionId === 3
        ? Yup.string().url("Invalid URL format.").required("URL is required.")
        : Yup.string(),
    marqueue:
      marqueeFormat === 1
        ? Yup.string().required("Marquee text is required.")
        : Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      order: 0,
      email: "",
      mobile: "",
      fromDate: "",
      toDate: "",
      url: "",
      marqueue: "",
    },
    validationSchema,
    validateOnMount: true, // Validate as soon as the form mounts
    onSubmit: (values: any) => {
      // Real-time submission is handled below in useEffect
    },
  });

  useEffect(() => {
    if (formik.isValid && formik.dirty) {
      onSubmit({
        ...formik.values,
        url: actionId === 3 ? formik.values.url : null,
        marqueue: marqueeFormat === 1 ? formik.values.marqueue : "",
      });
    }
  }, [formik.values, formik.isValid, formik.dirty]);

  return (
    <form style={{ width: "100%" }}>
      <h4>Fill all the details</h4>
      <div style={{ display: "flex", gap: 20 }}>
        <div>
          <label>Display Order:</label> <br />
          <select
            name="order"
            style={{ height: 55 }}
            value={formik.values.order}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          >
            <option value="0">-Select Display Order-</option>
            {DISPLAY_ORDER.map((pos) => (
              <option key={pos} value={pos}>
                {pos}
              </option>
            ))}
          </select>
          {formik.touched.order && formik.errors.order && (
            <div style={{ color: "red" }}>{formik.errors.order}</div>
          )}
        </div>
        <div>
          <label>From Date:</label> <br />
          <TextField
            type="date"
            name="fromDate"
            value={formik.values.fromDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.touched.fromDate && formik.errors.fromDate && (
            <div style={{ color: "red" }}>{formik.errors.fromDate}</div>
          )}
        </div>
        <div>
          <label>To Date:</label> <br />
          <TextField
            type="date"
            name="toDate"
            value={formik.values.toDate}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
          />
          {formik.touched.toDate && formik.errors.toDate && (
            <div style={{ color: "red" }}>{formik.errors.toDate}</div>
          )}
        </div>
      </div>
      <div style={{ marginTop: 10 }}>
        <label>Email Id:</label>
        <input
          name="email"
          style={{ width: "100%", height: 55 }}
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email && (
          <div style={{ color: "red" }}>{formik.errors.email}</div>
        )}
      </div>
      <div style={{ marginTop: 10 }}>
        <label>Mobile Number:</label>
        <input
          name="mobile"
          style={{ width: "100%", height: 55 }}
          value={formik.values.mobile}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.mobile && formik.errors.mobile && (
          <div style={{ color: "red" }}>{formik.errors.mobile}</div>
        )}
      </div>
      {actionId === 3 && (
        <div style={{ marginTop: 10 }}>
          <label>External Url:</label>
          <input
            name="url"
            style={{ width: "100%", height: 55 }}
            value={formik.values.url}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {formik.touched.url && formik.errors.url && (
            <div style={{ color: "red" }}>{formik.errors.url}</div>
          )}
        </div>
      )}
      {marqueeFormat === 1 && (
        <div style={{ marginTop: 20 }}>
          <label>Marquee Text:</label>
          <textarea
            name="marqueue"
            rows={4}
            cols={80}
            style={{ width: "100%" }}
            value={formik.values.marqueue}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          ></textarea>
          {formik.touched.marqueue && formik.errors.marqueue && (
            <div style={{ color: "red" }}>{formik.errors.marqueue}</div>
          )}
        </div>
      )}
    </form>
  );
}
