import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  List,
  ListItem,
  ListItemText,
  Button,
  Pagination,
  IconButton,
  ListItemAvatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
} from "@mui/material";

import DeleteIcon from "@mui/icons-material/Delete";
import {
  deleteDashboardPost,
  listAdsApi,
} from "../AppCore/services/DashboardPostServices";
import { imagesBucketPath } from "../AppCore/services/RecommendedBrokers";
import { useAuth } from "../context/AuthContext";

const AdsList: React.FC = () => {
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState<string>("");
  const { user } = useAuth();
  const [postSearchKeyword, setPostSearchKeyword] = useState<string>("");
  const [pagination, setPagination] = useState({
    totalPage: 0,
    totalRecord: 0,
    currentPage: 1,
  });

  const [adType1Data, setAdType1Data] = useState<any[]>([]);
  const [adType2Data, setAdType2Data] = useState<any[]>([]);
  const [selectedPost, setSelectedPost] = useState<any | null>(null);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const fetchPosts = async (
    adtype: number,
    pageNumber: number = 1,
    pageSize: number = 10
  ) => {
    setLoading(true);
    setError("");

    const request = {
      pageNumber,
      pageSize,
      adType: adtype,
      cityName: "",
    };

    try {
      const response = await listAdsApi(request);
      const data = response.data;

      if (adtype === 1) {
        setAdType1Data(data.posts || []);
      } else if (adtype === 2) {
        setAdType2Data(data.posts || []);
      }

      setPagination({
        totalPage: Math.max(1, data.totalPage || 1),
        totalRecord: data.totalRecord || 0,
        currentPage: pageNumber,
      });
    } catch (err) {
      console.error(err);
      setError("Failed to fetch posts. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (adType: number, _: any, value: number) => {
    fetchPosts(adType, value, 10); // Fixed pageSize to 10
  };

  const handleOpenDialog = (post: any) => {
    setSelectedPost(post);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedPost(null);
  };

  const handleConfirmDelete = async () => {
    if (!selectedPost) return;

    try {
      // 1. Store the current post info
      const postToDelete = { ...selectedPost };

      // 2. Immediately close dialog
      handleCloseDialog();

      // 3. Immediately update UI state
      if (postToDelete.marqueueText === "") {
        setAdType1Data((prevData) =>
          prevData.filter((post) => post.id !== postToDelete.id)
        );
      } else {
        setAdType2Data((prevData) =>
          prevData.filter((post) => post.id !== postToDelete.id)
        );
      }

      // 4. Make API call
      await deleteDashboardPost(
        "Dashboard/Marqueue",
        user.userId,
        postToDelete.postId,
        postToDelete.categoryId,
        postToDelete.id
      );
      const ftadType = selectedPost.marqueueText === "" ? 1 : 2;

      // 5. Refresh the data to ensure sync with server
      fetchPosts(ftadType, pagination.currentPage, 5);
    } catch (error) {
      console.error("Failed to delete post", error);
      setError("Failed to delete post. Please try again later.");

      // 6. If error occurs, refresh to restore correct statecl

      fetchPosts(selectedPost.adType, pagination.currentPage, 5);
    }
  };

  useEffect(() => {
    fetchPosts(1, 1, 5); // Fetch adType 1 posts
    fetchPosts(2, 1, 5); // Fetch adType 2 posts
  }, []);

  const defaultImagePath =
    "https://www.shutterstock.com/image-illustration/no-picture-available-placeholder-thumbnail-600nw-2179364083.jpg";

  const renderList = (data: any[], adType: number) => (
    <div style={{ marginBottom: 50 }}>
      <h3 style={{ marginBottom: 20 }}>
        {adType === 1 ? "Banners List" : "Marquee Text List"}
      </h3>
      {isLoading && <CircularProgress />}
      <List
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(1, 1fr)",
          gap: 5,
          width: "100%",
        }}
        style={{ width: "100%" }}
      >
        {data.map((post) => {
          const media = post.postMedias?.[0] || null;
          const mediaBlobId = `${imagesBucketPath}${media?.mediaBlobId}` || "";
          const mediaType = media?.mediaType || "";
          const validExtensions = ["png", "jpeg", "jpg", "gif", "webp"];
          const fileExtension = mediaBlobId.split(".").pop()?.toLowerCase();
          const isValidImage =
            fileExtension && validExtensions.includes(fileExtension);
          const mediaSrc = isValidImage ? mediaBlobId : defaultImagePath;
          const isVideo = mediaType.toLowerCase().includes("video");
          console.log(post, "post");
          return (
            <ListItem
              key={post.postId}
              sx={{
                border: "1px solid #ddd",
                borderRadius: 2,
              }}
            >
              <ListItemAvatar>
                {isVideo ? (
                  <video
                    width={100}
                    height={100}
                    src={mediaBlobId}
                    className="object-cover rounded"
                    style={{ marginRight: 20 }}
                    autoPlay={true}
                    muted={true}
                  />
                ) : (
                  <img
                    alt="media preview"
                    src={mediaSrc}
                    className="object-cover rounded"
                    style={{
                      height: 100,
                      width: 100,
                      marginRight: 20,
                      borderRadius: 10,
                    }}
                  />
                )}
              </ListItemAvatar>
              <ListItemText
                primary={post.marqueueText}
                secondary={`Location: ${post.cityName}, ${post.stateName}, ${post.countryName}`}
                sx={{ flex: 1 }}
              />
              <IconButton
                aria-label="delete"
                onClick={() => handleOpenDialog(post)}
                sx={{ color: "red" }}
              >
                <DeleteIcon />
              </IconButton>
            </ListItem>
          );
        })}
      </List>
      {error && <p style={{ color: "red" }}>{error}</p>}
      {!isLoading && data.length === 0 && <p>No posts found.</p>}
      {pagination.totalPage > 1 && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-evenly",
            marginTop: 20,
          }}
        >
          <Pagination
            count={pagination.totalPage}
            page={pagination.currentPage}
            onChange={(e, value) => handlePageChange(adType, e, value)}
          />
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Grid container spacing={2} sx={{ height: "120vh", padding: 2 }}>
        <Grid item xs={12} md={6} style={{ borderLeftColor: "GrayText" }}>
          {renderList(adType1Data, 1)}
        </Grid>
        <Grid item xs={12} md={6}>
          {renderList(adType2Data, 2)}
        </Grid>
      </Grid>

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <p>Are you sure you want to delete this post?</p>
          <p>
            <strong>{selectedPost?.marqueueText}</strong>
          </p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AdsList;
