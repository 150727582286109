import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import { CATEGORY, MarqueeFormat, MarqueueAction } from "./Dashboard/constant";
import GoogleMaps from "../Components/GoogleMaps";
import { IOption } from "../AppCore/types/DashboardPost";
import { v4 as uuidv4 } from "uuid";
import { useAuth } from "../context/AuthContext";
import PostList from "./Dashboard/PostLists";
import AddAdsForm from "../Components/AddAdsForm";
import UploadAds from "../Components/UploadAdsBanner";
import { uploadToS3 } from "../AppCore/services/awsServices";
import {
  AddAdsApi,
  AddBrandsApi,
  AddNewlyLaunchedApi,
  BrandsListApi,
} from "../AppCore/services/DashboardPostServices";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AddBrandsForm from "../Components/AddBrandsForm";

type BrandFilter = {
  key: string | number;
  value: string;
};

export default function AddNewPosts() {
  const { user } = useAuth();
  // console.log(user);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [category, setCategory] = React.useState<IOption | null>(null);
  const [postType, setPostType] = useState("");

  const [loading, setLoading] = useState(false);
  const [media, setMedia] = useState(null);
  const navigate = useNavigate();
  const [BrandsListData, setBrandsListData] = useState<{
    filters: BrandFilter[];
  } | null>(null);

  const [errors, setErrors] = useState<{
    marqueeType?: string;
    actionType?: string;
    categoryId?: string;
  }>({});
  const [formData, setFormData] = useState({
    postId: 0,
    displayOrder: 0,
    categoryId: 0,
    price: 0,
    title: "",
    isBrokerAppVerified: true,
    fromDate: "",
    toDate: "",
    cityName: "",
    stateName: "",
    countryName: "",
    placeName: "",
    placeID: "",
    geoLocationLatitude: 0,
    geoLocationLongitude: 0,
    viewportNorthEastLat: 0,
    viewportNorthEastLng: 0,
    viewportSouthWestLat: 0,
    viewportSouthWestLng: 0,
    isGlobal: true,
    userId: 0,
    email: "",
    contactNo: "",
    postMedias: [
      {
        mediaBlobId: "",
        mediaType: "",
        mediaHeight: 0,
        mediaWidth: 0,
      },
    ],
  });
  const steps = ["Select Your Category ", "Select the post", "Submit the form"];
  const validateStep = () => {
    const newErrors: any = {};
    if (activeStep === 0) {
      if (formData.categoryId === 0) {
        newErrors.categoryId = "Please Select Category.";
      }
      if (formData.placeID === "") {
        newErrors.cityName = "Please Select location.";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleNext = () => {
    if (!validateStep()) return;

    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleIsGlobalChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    setFormData((prev) => ({ ...prev, isGlobal: isChecked }));
  };

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleCategoryChange = (event: SelectChangeEvent) => {
    const selectedId = Number(event.target.value);
    const selectedCategory = CATEGORY.find((item) => item.id === selectedId);
    if (selectedCategory) {
      setCategory(selectedCategory);
      setFormData((prev) => ({ ...prev, categoryId: selectedId }));
    }
  };

  const matchedCategory = CATEGORY.find(
    (cat) => cat.id === formData.categoryId
  );
  const handleLocationChange = (
    city: string,
    state: string,
    country: string,
    placeId: string,
    placeName: string
  ) => {
    setFormData((prev) => ({
      ...prev,
      countryName: country,
      stateName: state,
      cityName: city,
      placeID: placeId,
      placeName,
    }));
  };
  const handleAdsFormSubmit = (data: any) => {
    setFormData((prev) => ({
      ...prev,
      toDate: data.toDate,
      email: data.email,
      fromDate: data.fromDate,
      contactNo: data.mobile,
      displayOrder: data.order,
      userId: user.userId,
    }));
  };

  const renderStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <FormControl
              sx={{ minWidth: 435, marginBottom: 1 }}
              error={!!errors.categoryId}
            >
              <InputLabel id="category-label">Select Category</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={formData.categoryId ? String(formData.categoryId) : ""}
                label="Category"
                onChange={handleCategoryChange}
              >
                {CATEGORY.map((item) => (
                  <MenuItem
                    key={item.id}
                    value={item.id}
                    disabled={!item.isActive}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
              <Typography color="error">{errors.categoryId}</Typography>
            </FormControl>

            <FormControl
              sx={{ minWidth: 435, marginBottom: 1 }}
              error={!!errors.categoryId}
            >
              <InputLabel id="category-label">Select Post Type</InputLabel>
              <Select
                labelId="category-label"
                id="category"
                value={postType}
                label="Category"
                onChange={(e) => setPostType(e.target.value)}
              >
                <MenuItem value="Newin">New In</MenuItem>
                <MenuItem value="NewlyLaunch">Newly Launched</MenuItem>
              </Select>
              <Typography color="error">{errors.categoryId}</Typography>
            </FormControl>

            <GoogleMaps onLocationChange={handleLocationChange} />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.isGlobal}
                  onChange={handleIsGlobalChange}
                  name="isGlobal"
                  color="primary"
                />
              }
              label="Is Global"
            />
          </div>
        );

      case 1:
        return (
          <Grid container spacing={2} sx={{ height: "160vh", padding: 2 }}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  padding: 2,
                  height: "100%",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  borderRadius: 1,
                }}
              >
                <PostList
                  categoryId={formData.categoryId}
                  categoryEndpoint={matchedCategory?.endpoint || ""}
                  cityName={formData.cityName}
                  userId={user.userId}
                  onPostSelect={handlePostSelect}
                  brandFilter={null}
                />
              </Box>
            </Grid>
          </Grid>
        );

      case 2:
        return (
          <Grid container spacing={2} sx={{ height: "120vh", padding: 2 }}>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 2,
                  height: "100%",
                  overflowY: "auto",
                  border: "1px solid #ddd",
                  borderRadius: 1,
                }}
              >
                <AddBrandsForm onSubmit={handleAdsFormSubmit} />
              </Box>
            </Grid>
          </Grid>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };
  const handlePostSelect = (post: any) => {
    console.log(post);
    if (post) {
      setFormData((prev) => ({
        ...prev,
        postId: post.postId,
        postMedias: post.postMedias,
        price: post.price,
        title: post.title,
      }));
    } else {
      console.log("Post deselected");
    }
  };
  const handleUpload = async () => {
    try {
      setLoading(true);
      console.log("FormData without media:", formData);

      const endpoint = postType === "NewlyLaunch" ? "NewlyLaunch" : "Newin";
      const res = await AddNewlyLaunchedApi(endpoint, formData);
      if (res.status === "success") {
        toast.success("Advertisement added successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });

        setTimeout(() => {
          navigate("/new-posts-list");
        }, 1000);
      }
      //     console.log(res);
      //   }
    } catch (error) {
      console.error("Upload error:", error);
      toast.error("Failed to upload advertisement. Please try again.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ width: "100%", mt: 4 }}>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <Box sx={{ mt: 4 }}>
        {renderStepContent(activeStep)}
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 4 }}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Back
          </Button>
          {activeStep < steps.length - 1 && (
            <Button variant="contained" onClick={handleNext}>
              Next
            </Button>
          )}
          {activeStep === 2 && (
            <Button variant="contained" color="primary" onClick={handleUpload}>
              Upload
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  );
}
