import {
  AddDashboardPostRequest,
  DeleteDashboardPostRequest,
  ListAdsRequest,
  ListDashboardPostRequest,
  SearchRequest,
} from "../types/DashboardPost";
import api from "./api";

export const searchPost = async (endpoint: string, request: SearchRequest) => {
  try {
    const response = await api.post(endpoint, { ...request });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const searchCityWisePost = async (request: SearchRequest) => {
  try {
    const response = await api.post("/Search/RealEstate/CityWise/Post", {
      ...request,
    });
    console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const AddAdsApi = async (request: any) => {
  try {
    const response = await api.post("/Dashboard/Marqueue/Add", { ...request });
    // console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const AddBrandsApi = async (request: any) => {
  try {
    const response = await api.post("/Dashboard/BrandAssociate/Add", {
      ...request,
    });
    // console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const AddNewlyLaunchedApi = async (endpoint: any, request: any) => {
  try {
    const response = await api.post(`/Dashboard/${endpoint}/Add`, {
      ...request,
    });

    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const NewlyLaunchedListApi = async (request: any) => {
  try {
    const response = await api.post("/Dashboard/NewlyLaunch/List", {
      ...request,
    });
    // console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const AddBrandsListApi = async (request: any) => {
  try {
    const response = await api.post("/Dashboard/BrandAssociate/List", {
      ...request,
    });
    // console.log(response);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const BrandsListApi = async (request: any) => {
  try {
    const response = await api.post("/Dashboard/BrandAssociate/Filters", {
      ...request,
    });

    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const listDashboardPost = async (
  endpoint: string,
  request: ListDashboardPostRequest
) => {
  try {
    const response = await api.post(`${endpoint}/List`, request);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
export const listAdsApi = async (request: ListAdsRequest) => {
  try {
    const response = await api.post("/Dashboard/Marqueue/List", request);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
// export const getPostByID = async () => {
//   try {
//     const response = await api.post(`Car/Post/PostDetail`, {postId: 16});
//     return response.data;
//   } catch (error: any) {
//     console.log("errr ", error);
//     return {
//       status: error.response.data.status,
//       error: error.response.data.statusMessage || "An error occurred",
//     };
//   }
// };
export const getPostByID = async () => {
  try {
    const response = await api.post(`Dashboard/Brokers/Count`);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};

export const addDashboardRealEstate = async (
  endpoint: string,
  request: AddDashboardPostRequest
) => {
  try {
    const response = await api.post(`${endpoint}/Add`, request);
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};

export const deleteDashboardPost = async (
  endpoint: string,
  userId: number,
  postId: number,
  categoryId: number,
  id: number
) => {
  try {
    const response = await api.delete(`${endpoint}/Delete`, {
      data: { userId, postId, categoryId, id: id },
    });
    return response.data;
  } catch (error: any) {
    console.log("errr ", error);
    return {
      status: error.response.data.status,
      error: error.response.data.statusMessage || "An error occurred",
    };
  }
};
