export const CATEGORY = [
  {
    id: 1,
    name: "RealEstate",
    isActive: true,
    endpoint: "/Search/RealEstate/CityWise/Post",
  },
  { id: 2, name: "Car", isActive: true, endpoint: "/Search/Car/CityWise/Post" },
  { id: 3, name: "Insurance", isActive: false, endpoint: "" },
  { id: 4, name: "Loan", isActive: false, endpoint: "" },
  { id: 5, name: "Wealth", isActive: false, endpoint: "" },
  { id: 6, name: "Travel", isActive: false, endpoint: "" },
];

export const SECTION = [
  { id: 1, name: "Marqueue", endpoint: "/Dashboard/Marqueue" },
  { id: 2, name: "Newly Launch", endpoint: "/Dashboard/NewlyLaunch" },
  { id: 3, name: "Brand Associate", endpoint: "/Dashboard/BrandAssociate" },
  { id: 4, name: "New In", endpoint: "/Dashboard/Newin" },
];
export const MarqueueAction = [
  { id: 1, name: "Post Details" },
  { id: 2, name: "Enquiry Form" },
  { id: 3, name: "External URL" },
];
export const SpaceCategory = [
  { category: "Newly Launch", id: 1 },
  { category: "Brand Associate", id: 2 },
  { category: "New In Property", id: 3 },
  { category: "NewInCar", id: 4 },
];
export const MarqueeFormat = [
  { id: 1, name: "Text" },
  { id: 2, name: "Video" },
  { id: 3, name: "Banner Image" },
  { id: 4, name: "Banner with GIF" },
];
export const ValidityTypePlan = [
  {
    id: 1,
    name: "Days",
  },
  { id: 2, name: "Hours" },
];
export const AdsCategoryPLan = [
  {
    id: 1,
    name: "Marquee",
  },
  { id: 2, name: "Carousel" },
];

export const DISPLAY_ORDER = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
];
export default function formatNumberToIndianSystem(number: number) {
  if (number === 0) {
    return "0"; // Explicitly handle zero
  }
  if (number) {
    if (number >= 10000000) {
      // Convert to Crores (1 Cr = 10,000,000)
      return (number / 10000000).toFixed(2) + " Cr";
    } else if (number >= 100000) {
      // Convert to Lakhs (1 Lac = 100,000)
      return (number / 100000).toFixed(1) + " Lac";
    } else if (number >= 1000) {
      // Convert to Thousands (1 K = 1,000)
      return (number / 1000).toFixed(1) + " K";
    } else {
      // Use the number as it is
      return number.toString();
    }
  } else {
    return "";
  }
}
