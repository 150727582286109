import React from "react";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const AccordionMenu = ({
  title,
  icon,
  isOpen,
  handleClick,
  subMenuItems = [],
}: any) => {
  return (
    <>
      <ListItemButton onClick={handleClick}>
        <div style={{ marginRight: "16px" }}>{icon}</div>
        <ListItemText primary={title} />
        {isOpen ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {subMenuItems.map((item: any, index: number) => (
            <ListItemButton
              key={index}
              sx={{ pl: 4 }}
              component={item.component}
              to={item.to}
            >
              <div style={{ marginRight: "16px" }}>{item.icon}</div>
              <ListItemText primary={item.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default AccordionMenu;
