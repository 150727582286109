import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import DashboardLayout from "./Components/Layout/layout";
import LoginPage from "./pages/Auth/Loginscreen";
import { KycUsersList } from "./pages/kycusers";
import { BrokerList } from "./pages/BrokerList";
import { RecommendedBrokerList } from "./pages/RecommendedBrokerList";
import UploadPodcast from "./pages/UploadPodcast";
import PodcastList from "./pages/PodcastList";
import { NonSubscriptionUserList } from "./pages/NonSubscriptionUserList";
import { SubscriptionUserList } from "./pages/Subscription";
import DashboardPostPage from "./pages/Dashboard/DashboardPostPage";
import AddAds from "./pages/AddAds";
import AdsList from "./pages/AdsList";
import AddBrands from "./pages/Addbrands";
import BrandsAssosiateList from "./pages/BrandsAssosiateList";
import AddNewPosts from "./pages/AddNewPosts";
import NewlyLaunchedList from "./pages/NewlyLaunchedList";
import AddPlans from "./pages/AddPlans";

function App() {
  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<DashboardLayout />}>
        <Route path="/dashboard" element={<KycUsersList />} />
        <Route path="/dashboard/post" element={<DashboardPostPage />} />
        <Route path="/brokerlist" element={<BrokerList />} />
        <Route path="/podcastlist" element={<PodcastList />} />
        <Route path="/recommended" element={<RecommendedBrokerList />} />
        <Route path="/upload-podcast" element={<UploadPodcast />} />
        <Route path="/non-subcription" element={<NonSubscriptionUserList />} />
        <Route path="/add-ads" element={<AddAds />} />
        <Route path="/add-posts" element={<AddNewPosts />} />
        <Route path="/new-posts-list" element={<NewlyLaunchedList />} />
        <Route path="/ads-list" element={<AdsList />} />
        <Route path="/brands-list" element={<BrandsAssosiateList />} />
        <Route path="/add-brands" element={<AddBrands />} />
        <Route path="/add-plans" element={<AddPlans />} />
        <Route path="/subcription" element={<SubscriptionUserList />} />
        {/* <Route path="/kycusers" element={<KycUsersList />} />  */}
      </Route>
    </Routes>
  );
}

export default App;
