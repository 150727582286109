// ThumbnailGenerator.js
import {
  Button,
  Grid,
  CircularProgress,
  Modal,
  Alert,
  Snackbar,
} from "@mui/material";
import React, { useState, useRef } from "react";
import Dropzone from "react-dropzone";
import { ToastContainer } from "react-toastify";
import { ButtonStyles } from "../theme/colors";

const UploadAds = ({
  marqueeFormat,
  onMediaSelect,
  onThumbnailGenerate,
  onThumbnailSelect,
  loading,
  media,
  thumbnail,
}) => {
  const thumbnailInputRef = useRef(null);
  const [alert, setAlert] = useState({
    open: false,
    message: "",
    severity: "",
  });
  const handleAlertClose = () => {
    setAlert({ ...alert, open: false });
  };

  const MAX_VIDEO_SIZE = 10 * 1024 * 1024; // 10MB
  const MAX_IMAGE_SIZE = 2 * 1024 * 1024; // 2MB
  const MAX_GIF_SIZE = 2 * 1024 * 1024;

  const onDrop = (acceptedFiles) => {
    if (acceptedFiles && acceptedFiles.length > 0) {
      const file = acceptedFiles[0];

      if (
        (marqueeFormat === 3 && file.size > MAX_IMAGE_SIZE) ||
        (marqueeFormat === 4 && file.size > MAX_GIF_SIZE)
      ) {
        alert(
          `File size exceeds the limit. Current size: ${(
            file.size /
            1024 /
            1024
          ).toFixed(2)}MB`
        );
        return;
      }

      const url = URL.createObjectURL(file);

      if (marqueeFormat === 2) {
        // Video validation
        const videoElement = document.createElement("video");
        videoElement.src = url;

        videoElement.onloadedmetadata = () => {
          // const width = videoElement.videoWidth;
          // const height = videoElement.videoHeight;
          // const aspectRatio = width / height;
          const duration = videoElement.duration;

          if (duration > 10) {
            setAlert({
              open: true,
              message: `Video duration exceeds the 10-second limit. Duration: ${duration.toFixed(
                2
              )}s`,
              severity: "error",
            });
            return;
          }

          onMediaSelect({
            file,
            url,
            type: file.type,
            path: file.path,
          });

          generateThumbnail(file, 3, 5);
        };
      } else if (marqueeFormat === 3 || marqueeFormat === 4) {
        // Image or GIF validation
        const imageElement = document.createElement("img");
        imageElement.src = url;

        imageElement.onload = () => {
          const width = imageElement.width;
          const height = imageElement.height;
          const aspectRatio = width / height;

          if (Math.abs(aspectRatio - 16 / 9) > 0.01) {
            // alert(
            //   `Invalid dimensions for image/gif. Expected 16:9, but got ${width}x${height}`
            // );
            setAlert({
              open: true,
              message: `Invalid dimensions for image/gif. Expected 16:9, but got ${width}x${height}`,
              severity: "error",
            });
            return;
          }

          onMediaSelect({
            file,
            url,
            type: file.type,
            path: file.path,
          });
        };
      }
    }
  };

  const generateThumbnail = (videoFile, startTime, endTime) => {
    const video = document.createElement("video");
    video.src = URL.createObjectURL(videoFile);
    video.currentTime = startTime;

    video.addEventListener("loadeddata", () => {
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth / 2;
      canvas.height = video.videoHeight / 2;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

      const thumbnailURL = canvas.toDataURL("image/jpeg");

      const base64Data = thumbnailURL.split(",")[1];
      const mimeType = thumbnailURL.split(";")[0].slice(5);
      const byteCharacters = atob(base64Data);
      const byteArrays = [];

      for (let offset = 0; offset < byteCharacters.length; offset += 512) {
        const slice = byteCharacters.slice(offset, offset + 512);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: mimeType });
      const thumbnailFile = new File([blob], "thumbnail.jpeg", {
        type: mimeType,
      });

      onThumbnailGenerate({
        file: thumbnailFile,
        url: thumbnailURL,
      });
    });

    setTimeout(() => {
      video.pause();
      video.currentTime = 0;
    }, (endTime - startTime) * 1000);
  };

  const handleThumbnailInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        onThumbnailSelect({
          file,
          url: reader.result,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const openThumbnailInput = () => {
    thumbnailInputRef.current?.click();
  };

  return (
    <Grid container spacing={3}>
      {media && (
        <Grid item xs={12}>
          <div>
            <h3>
              {marqueeFormat === 2
                ? "Video:"
                : marqueeFormat === 3
                ? "Image:"
                : "GIF:"}
            </h3>
            {marqueeFormat === 2 ? (
              <video controls width="100%" src={media} />
            ) : (
              <img src={media} alt="Media Preview" style={thumbnailStyle} />
            )}
            {thumbnail && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  flexDirection: "column",
                }}
              >
                <h3>Thumbnail:</h3>
                <img
                  src={thumbnail}
                  alt="Video Thumbnail"
                  style={thumbnailStyle}
                />
                <Button
                  variant="outlined"
                  style={ButtonStyles}
                  onClick={openThumbnailInput}
                >
                  Select Thumbnail
                </Button>
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleThumbnailInputChange}
                  ref={thumbnailInputRef}
                />
              </div>
            )}
          </div>
        </Grid>
      )}

      <Grid item xs={12}>
        {!media && (
          <Dropzone
            onDrop={onDrop}
            accept={
              marqueeFormat === 2
                ? {
                    "video/*": [
                      ".mp4",
                      ".mov",
                      ".wmv",
                      ".avi",
                      ".mkv",
                      ".flv",
                      ".webm",
                    ],
                  }
                : marqueeFormat === 3
                ? {
                    "image/*": [".png", ".jpeg", ".webp"],
                  }
                : {
                    "image/gif": [".gif"],
                  }
            }
            multiple={false}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()} style={dropzoneStyle}>
                <input {...getInputProps()} />
                <p>
                  Drag 'n' drop a{" "}
                  {marqueeFormat === 2
                    ? "video"
                    : marqueeFormat === 3
                    ? "image"
                    : "GIF"}{" "}
                  file here, or click to select one
                </p>
              </div>
            )}
          </Dropzone>
        )}
      </Grid>
      <Snackbar
        open={alert.open}
        autoHideDuration={6000}
        onClose={handleAlertClose}
      >
        <Alert
          onClose={handleAlertClose}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
      <ToastContainer />
      <Modal
        open={loading}
        onClose={() => {}}
        aria-labelledby="loading-modal"
        aria-describedby="loading-modal-description"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
          <p style={{ marginTop: 16, color: "white" }}>Uploading...</p>
        </div>
      </Modal>
    </Grid>
  );
};

const dropzoneStyle = {
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
  height: "200px",
  justifyContent: "center",
  alignItems: "center",
  display: "flex",
};

const thumbnailStyle = {
  maxWidth: "100%",
  maxHeight: "100%",
  margin: "10px 0",
};

export default UploadAds;
